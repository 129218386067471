import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';

const SpacerStyle = createOwnUpStyle({
  paddingBottom: 50
});

export const Spacer = createOwnUpComponent(OwnUpBox, SpacerStyle);

const ResponsiveSpacerStyle = createOwnUpStyle({
  variants: {
    medium: {
      paddingBottom: 50
    },
    smallAndDown: {
      paddingBottom: 20
    }
  }
});

export const ResponsiveSpacer = createOwnUpComponent(OwnUpBox, ResponsiveSpacerStyle);
